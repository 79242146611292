import { parseISO } from "date-fns";

const numberFormat = new Intl.NumberFormat('ko-KR');

export const displayPrice = (price: number) => {
    return `₩${numberFormat.format(price)}`;
}

export const displayDateTime = (isoDate: string) => {
    return parseISO(isoDate).toLocaleString();
};

export const displayDate = (isoDate: string) => {
    return parseISO(isoDate).toLocaleDateString();
};

export const displayTime = (isoDate: string) => {
    return parseISO(isoDate).toLocaleTimeString();
};