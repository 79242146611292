import { IPaginatable, httpGet, httpPutEmpty } from './HttpService';

export enum CommissionType {
    Self = 1,
    Basic,
    Simple,
    Advance,
}

export const getCommissionTypeName = (type: CommissionType): string => {
    switch (type) {
        case CommissionType.Self:
            return "직접출원";
        case CommissionType.Basic:
            return "바로출원";
        case CommissionType.Simple:
            return "간편출원";
        case CommissionType.Advance:
            return "심화출원";
    }
};

export enum OrderItemStep {
    Pending = 1,
    Request,
    Procedure,
    Screening,
    Report,
    Complete
};

export const getOrderItemStepName = (step: OrderItemStep): string => {
    switch (step) {
        case OrderItemStep.Pending:
            return "대기";
        case OrderItemStep.Request:
            return "출원의뢰";
        case OrderItemStep.Procedure:
            return "출원진행";
        case OrderItemStep.Screening:
            return "심사중";
        case OrderItemStep.Report:
            return "심사결과발표";
        case OrderItemStep.Complete:
            return "등록완료";
    }
};

export enum OrderPaymentStatus {
    Pending = 1,
    WaitingForDeposit,
    Done,
    Canceled
}

export const getOrderPaymentStatusName = (status: OrderPaymentStatus): string => {
    switch (status) {
        case OrderPaymentStatus.Pending:
            return "결제 대기";
        case OrderPaymentStatus.WaitingForDeposit:
            return "입금 대기";
        case OrderPaymentStatus.Done:
            return "완료";
        case OrderPaymentStatus.Canceled:
            return "취소";
    }
}

interface IOperatorInfo {
    fullName: string;
    email: string;
    phoneNumber: string;
    telephoneNumber?: string;
}

interface IApplicantInfo {
    koreanFullName: string;
    englishFullName: string;
    email: string;
    phoneNumber: string;
    applicantCode: string;
}

interface IOrderItemStepHistory {
    id: string;
    utcCreatedDate: string;
    step: OrderItemStep;
    description?: string;
}

export interface IOrderRow {
    id: string;
    publicNumber: number;
    UtcCreatedDate: string;
    userId: string;
    userName: string;
    fullName: string;
    totalAmountWIthVAT: number;
    koreanTrademarkName: string;
    englishTrademarkName: string;
    paymentStatus: OrderPaymentStatus;
    applicant: IApplicantInfo;
    operator: IOperatorInfo;
}

export interface IOrderItem {
    id: string;
    category: string;
    classificationCode: string;
    classificationDescription: string;
    checkPoint?: number;
    commissionType: CommissionType;
    commission: number;
    isQuick: boolean;
    quickCommission: number;
    officialFee: number;
    officeFee: number;
    amount: number;
    currentStep: OrderItemStep;
    stepHistories: Array<IOrderItemStepHistory>;
}

export interface IOrderItemGroup {
    commissionType: CommissionType;
    isQuick: boolean;
    currentStep: OrderItemStep;
    items: Array<IOrderItem>;
}

export interface IOrderDetail {
    id: string;
    publicNumber: number;
    utcCreatedDate: string;
    userId: string;
    userName: string;
    fullName: string;
    applicant: IApplicantInfo;
    operator: IOperatorInfo;
    koreanTrademarkName: string;
    englishTrademarkName: string;
    description: string;
    totalAmountWIthVAT: number;
    paymentStatus: OrderPaymentStatus;
    stampId?: string;
    itemGroups: Array<IOrderItemGroup>;
}

export interface IOrderSearchBindingModel {
    koreanTrademarkName?: string;
    englishTrademarkName?: string;
    applicantCode?: string;
    user?: string;
    orderOperator?: string;
    applicant?: string;
    pageIndex?: number;
    pageSize?: number;
}

export const getDefaultOrderSearchBindingModel = (): IOrderSearchBindingModel => {
    return {
        koreanTrademarkName: undefined,
        englishTrademarkName: undefined,
        applicantCode: undefined,
        user: undefined,
        orderOperator: undefined,
        applicant: undefined,
        pageIndex: 0,
        pageSize: 10
    };
}

export const getOrders = async (model: IOrderSearchBindingModel) => {
    const result = await httpGet<IPaginatable<IOrderRow>>("/orders", model);

    return result;
}

export const getOrderDetails = async (orderId: string) => {
    const result = await httpGet<IOrderDetail>(`/orders/${orderId}`);

    return result;
}

export const putHistory = async (orderId: string, itemIds: Array<string>, step: OrderItemStep, description: string) => {
    const result = await httpPutEmpty(`/orders/${orderId}`, {
        itemIds: itemIds,
        step: step,
        description: description
    });

    return result;
}

export const putPayment = async (orderId: string, paymentStatus: OrderPaymentStatus, comment?: string | undefined) => {
    const result = await httpPutEmpty(`/orders/${orderId}/payment`, {
        status: paymentStatus,
        comment: comment
    });

    return result;
}