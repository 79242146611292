import { Tag } from "antd";
import { OrderPaymentStatus, getOrderPaymentStatusName } from "../services/OrderService";

const OrderPaymentStatusTag: React.FC<{ status: OrderPaymentStatus }> = ({ status }) => {
    switch (status) {
        case OrderPaymentStatus.Pending:
            return <Tag color="yellow">{getOrderPaymentStatusName(OrderPaymentStatus.Pending)}</Tag>;
        case OrderPaymentStatus.WaitingForDeposit:
            return <Tag color="lime">{getOrderPaymentStatusName(OrderPaymentStatus.WaitingForDeposit)}</Tag>;
        case OrderPaymentStatus.Done:
            return <Tag color="success">{getOrderPaymentStatusName(OrderPaymentStatus.Done)}</Tag>;
        case OrderPaymentStatus.Canceled:
            return <Tag color="error">{getOrderPaymentStatusName(OrderPaymentStatus.Canceled)}</Tag>;
    }
}

export default OrderPaymentStatusTag;