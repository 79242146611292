import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import LoadingState from '../states/LoadingState';
import { getDefaultUserSearchBindingModel, getUsers } from '../services/UserService';
import { showErrorModal } from '../components/MModal';
import { Button, Col, Descriptions, Form, Input, Row, Space, Table, theme } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { displayDateTime } from '../utilities/FormatUtil';
import OrderSearchState from '../states/OrderSearchState';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from './RoutePath';
import UserSearchState from '../states/UserSearchState';
import { scrollToTop } from '../utilities/ScrollUtil';

const User: React.FC = () => {
    const [users, setUsers] = useState<Array<any>>([]);
    const [totalItemCount, setTotalItemCount] = useState(0);
    const [, setLoadingState] = useRecoilState(LoadingState);
    const [userSearchState, setUserSearchState] = useRecoilState(UserSearchState);
    const [orderSearchState, setOrderSearchState] = useRecoilState(OrderSearchState);
    const naviage = useNavigate();
    const { token } = theme.useToken();
    const [form] = Form.useForm();

    const handleChangedIndex = (index: number) => {
        scrollToTop();
        setUserSearchState({ ...userSearchState, pageIndex: index - 1 });
    };

    useEffect(() => {
        const loadUsers = async () => {
            setLoadingState(true);
            const result = await getUsers(userSearchState);
            if (result.isSuccess) {
                setUsers(result.dto!.items);
                setTotalItemCount(result.dto!.totalItemCount);
            } else {
                showErrorModal(result.error);
            }
            setLoadingState(false);
        }

        form.setFieldsValue(userSearchState);

        loadUsers();
    }, [userSearchState, form, setLoadingState]);

    const columns: ColumnsType<any> = [
        {
            title: "이름",
            dataIndex: "fullName",
        },
        {
            title: "이메일",
            dataIndex: "userName",
        },
        {
            title: "외부인증",
            render: (_, record) => <span>{record.logins?.join(", ")}</span>
        },
        {
            title: "마지막 로그인",
            dataIndex: "utcLastActivedDate",
            render: (value: string) => displayDateTime(value)
        },
        {
            title: "가입일",
            dataIndex: "utcCreatedDate",
            render: (value: string) => displayDateTime(value)
        },
        {
            title: "",
            render: (_, record) => (
                <Button type="link"
                    onClick={() => {
                        setOrderSearchState({ ...orderSearchState, user: record.userName, pageIndex: 0 });
                        naviage(RoutePath.Order.Path);
                    }}
                >출원 요청 보기</Button>
            )
        }
    ];

    return (
        <>
            <Form form={form} layout="horizontal" style={{ backgroundColor: token.colorFillAlter, borderRadius: token.borderRadiusLG, padding: 24, marginBottom: 16 }}
                onFinish={(values: any) => setUserSearchState({ ...userSearchState, ...values, pageIndex: 0 })}
                onReset={() => setUserSearchState(getDefaultUserSearchBindingModel())}>
                <Row gutter={[32, 0]}>
                    <Col span={12}>
                        <Form.Item label="이메일" name="email">
                            <Input maxLength={300} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="이름" name="fullName">
                            <Input maxLength={100} />
                        </Form.Item>
                    </Col>
                </Row>
                <div style={{ textAlign: "right" }}>
                    <Space size="small" direction="horizontal">
                        <Button type="primary" htmlType="submit">검색</Button>
                        <Button htmlType="reset">리셋</Button>
                    </Space>
                </div>
            </Form>
            <Descriptions>
                <Descriptions.Item label="검색결과">{totalItemCount}</Descriptions.Item>
            </Descriptions>
            <Table dataSource={users} columns={columns} rowKey={(r) => r.id}
                pagination={{ total: totalItemCount, onChange: handleChangedIndex, pageSize: userSearchState.pageSize, current: (userSearchState.pageIndex ?? 0) + 1 }} />
        </>
    );
}

export default User;