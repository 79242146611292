import { Tag } from "antd";
import { OrderItemStep, getOrderItemStepName } from "../services/OrderService";

const OrderItemStepTag: React.FC<{ step: OrderItemStep }> = ({ step }) => {
    switch (step) {
        case OrderItemStep.Pending:
            return <Tag color="gold">{getOrderItemStepName(OrderItemStep.Pending)}</Tag>;
        case OrderItemStep.Request:
            return <Tag color="lime">{getOrderItemStepName(OrderItemStep.Request)}</Tag>;
        case OrderItemStep.Procedure:
            return <Tag color="green">{getOrderItemStepName(OrderItemStep.Procedure)}</Tag>;
        case OrderItemStep.Screening:
            return <Tag color="cyan">{getOrderItemStepName(OrderItemStep.Screening)}</Tag>;
        case OrderItemStep.Report:
            return <Tag color="geekblue">{getOrderItemStepName(OrderItemStep.Report)}</Tag>;
        case OrderItemStep.Complete:
            return <Tag color="purple">{getOrderItemStepName(OrderItemStep.Complete)}</Tag>;
    }
}

export default OrderItemStepTag;