import { httpGet } from "./HttpService";

export interface IStampUrl {
    original: string;
    backgroundRemoval: string;
}

export const getStampViewUrl = async (stampId: string) => {
    const result = await httpGet<IStampUrl>(`/stamps/${stampId}`);

    return result;
}

export const getStampDownloadUrl = async (stampId: string, fileName: string) => {
    const result = await httpGet<IStampUrl>(`/stamps/${stampId}`, { fileName: fileName });

    return result;
}