import { httpGet } from './HttpService';

export interface IStatistics {
    userCount: number;
    orderCount: number;
    sumOrderPayment: number;
}

export const getDefaultStatistics = (): IStatistics => {
    return {
        userCount: 0,
        orderCount: 0,
        sumOrderPayment: 0,
    };
}

export const getStatistics = async () => {
    const result = await httpGet<IStatistics>("/statistics");

    return result;
}