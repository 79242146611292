import { Card, Col, Row, Statistic } from 'antd';
import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import LoadingState from '../states/LoadingState';
import { IStatistics, getDefaultStatistics, getStatistics } from '../services/StatisticsService';
import { showErrorModal } from '../components/MModal';

const Home: React.FC = () => {
    const [, setLoadingState] = useRecoilState(LoadingState);
    const [statistics, setStatistics] = useState<IStatistics>(getDefaultStatistics());
    
    useEffect(() => {
        const loadUsers = async () => {
            setLoadingState(true);
            const result = await getStatistics();
            if (result.isSuccess) {
                setStatistics(result.dto!)
            } else {
                showErrorModal(result.error);
            }
            setLoadingState(false);
        }

        loadUsers();
    }, [setLoadingState]);

    return (
        <div>
            <Row gutter={[32, 16]}>
                <Col span={8}>
                    <Card bordered={true}>
                        <Statistic title="회원" value={statistics.userCount} suffix="명" />
                    </Card>
                </Col>
                <Col span={8}>
                    <Card bordered={true}>
                        <Statistic title="출원 요청" value={statistics.orderCount} suffix="건" />
                    </Card>
                </Col>
                <Col span={8}>
                    <Card bordered={true}>
                        <Statistic title="결제" value={statistics.sumOrderPayment} suffix="원" />
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default Home;