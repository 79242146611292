import { httpGet } from "./HttpService";

export interface IUserSearchBindingModel {
    pageSize?: number;
    pageIndex?: number;
    email?: string;
    fullName?: string;
    phoneNumber?: string;
}

export const getDefaultUserSearchBindingModel = (): IUserSearchBindingModel => {
    return {
        email: undefined,
        fullName: undefined,
        phoneNumber: undefined,
        pageSize: 10,
        pageIndex: 0,
    };
}

export const getUsers = async (model: IUserSearchBindingModel) => {
    const result = await httpGet<any>("/users", model);

    return result;
};

export const changePassword = async (userId: string, password: string) => { 
    const result = await httpGet(`/users/${userId}/change-password`, {
        password: password
    });

    return result;
};