import { Tag, Tooltip } from "antd";

const PaymentStatusTag: React.FC<{ status: string }> = ({ status }) => {
    switch (status) {
        case "IN_PROGRESS":
            return <Tooltip title="결제수단 정보와 해당 결제수단의 소유자가 맞는지 인증을 마친 상태입니다.">
                <Tag color="warning">IN_PROGRESS</Tag>
            </Tooltip>;
        case "WAITING_FOR_DEPOSIT":
            return <Tooltip title="결제 고객이 발급된 가상계좌에 입금하는 것을 기다리고 있는 상태입니다.">
                <Tag color="lime">WAITING_FOR_DEPOSIT</Tag>
            </Tooltip>;
        case "DONE":
            return <Tooltip title="인증된 결제수단 정보, 고객 정보로 요청한 결제가 승인된 상태입니다.">
                <Tag color="success">DONE</Tag>
            </Tooltip>;
        case "CANCELED":
            return <Tooltip title="승인된 결제가 취소된 상태입니다.">
                <Tag color="error">CANCELED</Tag>
            </Tooltip>;
        case "PARTIAL_CANCELED":
            return <Tooltip title="승인된 결제가 부분 취소된 상태입니다.">
                <Tag color="error">PARTIAL_CANCELED</Tag>
            </Tooltip>;
        case "ABORTED":
            return <Tooltip title="결제 승인이 실패한 상태입니다.">
                <Tag color="error">ABORTED</Tag>
            </Tooltip>;
        case "EXPIRED":
            return <Tooltip title="결제 유효 시간 30분이 지나 거래가 취소된 상태입니다.">
                <Tag color="error">EXPIRED</Tag>
            </Tooltip>;
        default:
            return <Tag color="default">{status}</Tag>;
    }
}

export default PaymentStatusTag;