import { IPaginatable, httpGet } from './HttpService';

export enum PaymentActionType {
    Confirm = 1,
    PaymentStatusChanged,
    DepositCallback,
    Manual
}

export const getPaymentActionTypeName = (type: PaymentActionType) => {
    switch (type) {
        case PaymentActionType.Confirm:
            return "결제 승인 요청";
        case PaymentActionType.PaymentStatusChanged:
            return "결제 상태 변경";
        case PaymentActionType.DepositCallback:
            return "가상계좌 콜백";
        case PaymentActionType.Manual:
            return "관리자 결제";
    }
};

const BankCode: { [key: string]: string } = {
    "39": "경남은행",
    "34": "광주은행",
    "S8": "교보증권",
    "12": "단위농협(지역농축협)",
    "SE": "대신증권",
    "SK": "메리츠증권",
    "S5": "미래에셋증권",
    "SM": "부국증권",
    "32": "부산은행",
    "S3": "삼성증권",
    "45": "새마을금고",
    "64": "산림조합",
    "SN": "신영증권",
    "S2": "신한금융투자",
    "88": "신한은행",
    "48": "신협",
    "27": "씨티은행",
    "20": "우리은행",
    "71": "우체국예금보험",
    "S0": "유안타증권",
    "SJ": "유진투자증권",
    "50": "저축은행중앙회",
    "37": "전북은행",
    "35": "제주은행",
    "90": "카카오뱅크",
    "SQ": "카카오페이증권",
    "89": "케이뱅크",
    "SB": "키움증권",
    "92": "토스뱅크",
    "ST": "토스증권",
    "SR": "펀드온라인코리아(한국포스증권)",
    "SH": "하나금융투자",
    "81": "하나은행",
    "S9": "하이투자증권",
    "S6": "한국투자증권",
    "SG": "한화투자증권",
    "SA": "현대차증권",
    "54": "홍콩상하이은행",
    "SI": "DB금융투자",
    "31": "DGB대구은행",
    "03": "IBK기업은행",
    "06": "KB국민은행",
    "S4": "KB증권",
    "02": "KDB산업은행",
    "SP": "KTB투자증권(다올투자증권)",
    "SO": "LIG투자증권",
    "11": "NH농협은행",
    "SL": "NH투자증권",
    "23": "SC제일은행",
    "07": "Sh수협은행",
    "SD": "SK증권"
};

export const getBankName = (code: string) => {
    return BankCode[code];
}

const CardCode: { [key: string]: string } = {
    // 국내
    "3K": "기업 BC",
    "46": "광주은행",
    "71": "롯데카드",
    "30": "KDB산업은행",
    "31": "BC카드",
    "51": "삼성카드",
    "38": "새마을금고",
    "41": "신한카드",
    "62": "신협",
    "36": "씨티카드",
    "33": "우리BC카드(BC 매입)",
    "W1": "우리카드(우리 매입)",
    "37": "우체국예금보험",
    "39": "저축은행중앙회",
    "35": "전북은행",
    "42": "제주은행",
    "15": "카카오뱅크",
    "3A": "케이뱅크",
    "24": "토스뱅크",
    "21": "하나카드",
    "61": "현대카드",
    "11": "KB국민카드",
    "91": "NH농협카드",
    "34": "Sh수협은행",
    //해외
    "6D": "다이너스 클럽",
    "6I": "디스커버",
    "4M": "마스터카드",
    "3C": "유니온페이",
    "7A": "아메리칸 익스프레스",
    "4J": "JCB",
    "4V": "VISA"
};

export const getCardName = (code: string) => {
    return CardCode[code];
}

export const getAcquireStatusName = (status: string) => {
    switch (status) {
        case "READY":
            return "매입 요청 준비";
        case "REQUESTED":
            return "매입 요청";
        case "COMPLETED":
            return "매입 완료";
        case "CANCEL_REQUESTED":
            return "매입 취소 요청";
        case "CANCELED":
            return "매입 취소 완료";
        case "":
            return "";
    }
};

export const getInterestPayerName = (payer: string) => {
    switch (payer) {
        case "BUYER":
            return "고객";
        case "CARD_COMPANY":
            return "카드사";
        case "MERCHANT":
            return "상점";
    }
};

export const getPaymentHistories = async (pageIndex: number, pageSize?: number, orderId?: string) => {
    const result = await httpGet<IPaginatable<any>>("/payment-histories", {
        pageSize: pageSize ?? 10,
        pageIndex: pageIndex,
        orderId: orderId
    });

    return result;
}

export const getPaymentHistoryDetails = async (id: string) => {
    const result = await httpGet<any>(`/payment-histories/${id}`);

    return result;
}
