import { List, StepProps, Steps } from "antd";
import { IOrderItem, OrderItemStep, getOrderItemStepName } from "../services/OrderService";
import { displayDateTime } from "../utilities/FormatUtil";

const OrderItemProgress: React.FC<{ item: IOrderItem }> = ({ item }) => {
    const createItem = (step: OrderItemStep): StepProps => {
        const histories = item.stepHistories.filter(h => h.step === step);

        return {
            title: getOrderItemStepName(step),
            description: histories.length > 0 && <List dataSource={histories} itemLayout="horizontal"
                renderItem={
                    (history, index) => {
                        return <List.Item>
                            <List.Item.Meta title={displayDateTime(history.utcCreatedDate)} description={history.description} />
                        </List.Item>;
                    }
                }
            />
        }
    };

    return (
        <Steps
            status={item.currentStep === OrderItemStep.Complete ? "finish" : "process"}
            direction="horizontal"
            current={item.currentStep - 2}
            items={
                [
                    createItem(OrderItemStep.Request),
                    createItem(OrderItemStep.Procedure),
                    createItem(OrderItemStep.Screening),
                    createItem(OrderItemStep.Report),
                    createItem(OrderItemStep.Complete),
                ]
            }
        />
    );
}

export default OrderItemProgress;