import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { BrowserRouter } from "react-router-dom";
import { theme, ConfigProvider, Spin } from 'antd';
import 'antd/dist/reset.css';
import AuthProvider from './components/AuthProvider';
import LogIn from './views/Login';
import Main from './views/Main';
import koKR from 'antd/locale/ko_KR';
import "./styles/App.scss";
import LoadingState from './states/LoadingState';
import { httpInit } from './services/HttpService';
import AuthState from './states/AuthState';
import { AuthStorageService } from './services/AuthStorageService';

const App: React.FC = () => {
  httpInit();

  const loadingState = useRecoilValue(LoadingState);
  const [, setAuthenticated] = useRecoilState(AuthState);

  useEffect(() => {
    const authModel = AuthStorageService.load();
    setAuthenticated(authModel);
  }, [setAuthenticated]);

  return (
    <BrowserRouter>
      <ConfigProvider theme={{ algorithm: theme.defaultAlgorithm }} locale={koKR}>
        <Spin spinning={loadingState} style={{ maxHeight: "100vh" }}>
          <AuthProvider
            unauthenticate={<LogIn />}
            authenticate={<Main />}
          />
        </Spin>
      </ConfigProvider>
    </BrowserRouter>
  );
}

export default App;