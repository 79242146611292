import { Tag } from "antd";
import { CommissionType } from "../services/OrderService";

const CommissionTag: React.FC<{ type: CommissionType }> = ({ type }) => {
    switch (type) {
        case CommissionType.Basic:
            return <Tag color="magenta">바로출원</Tag>;
        case CommissionType.Simple:
            return <Tag color="red">간편출원</Tag>;
        case CommissionType.Advance:
            return <Tag color="volcano">심화출원</Tag>;
        default:
            return <Tag color="orange">직접출원</Tag>;
    }
}

export default CommissionTag;