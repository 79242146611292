import React from 'react';
import { Button, Form, Input, Layout, Space, theme } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useRecoilState } from 'recoil';
import AuthState from '../states/AuthState';
import { getToken } from '../services/AuthService';
import LoadingState from '../states/LoadingState';
import { showErrorModal } from '../components/MModal';
import { AuthStorageService } from '../services/AuthStorageService';

const { Content } = Layout;

const LogIn: React.FC = () => {
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const [, setAuthenticated] = useRecoilState(AuthState);
    const [, setLoadingState] = useRecoilState(LoadingState);

    const handleFormFinish = async (values: any) => {
        setLoadingState(true);
        const response = await getToken({ email: values.username, password: values.password });
        setLoadingState(false);

        if (response.isSuccess) {
            const newModel = { isAuthenticated: true, accessToken: response.dto!.accessToken };
            AuthStorageService.save(newModel);
            setAuthenticated(newModel);
        } else {
            showErrorModal(response.error!);
        }
    }

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Content style={{ padding: '0 50px', position: 'relative' }}>
                <Space direction='vertical' size='large' style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translateX(-50%) translateY(-50%)', backgroundColor: colorBgContainer, padding: 20 }}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className='logo'></div>
                    </div>
                    <Form
                        name="normal_login"
                        style={{ width: 300 }}
                        onFinish={handleFormFinish}>
                        <Form.Item
                            name="username"
                            rules={[{ required: true, message: '이메일을 입력하세요.' }, { type: 'email', message: '이메일 형식으로 입력하세요.' }]}>
                            <Input prefix={<UserOutlined />} placeholder="이메일" />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[{ required: true, message: '비밀번호를 입력하세요.' }]}>
                            <Input
                                prefix={<LockOutlined />}
                                type="password"
                                placeholder="비밀번호" />
                        </Form.Item>
                        <Form.Item style={{ textAlign: 'center' }}>
                            <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                                로그인
                            </Button>
                        </Form.Item>
                    </Form>
                </Space>
            </Content>
        </Layout>
    );
}

export default LogIn;