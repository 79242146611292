
const storageKey = "auth";

const defaultModel: AuthModel = {
    isAuthenticated: false
};

export interface AuthModel {
    isAuthenticated: boolean;
    accessToken?: string | undefined;
}

export class AuthStorageService {
    static save(model: AuthModel) {
        localStorage.setItem(storageKey, btoa(JSON.stringify(model)))
    }
    
    static load() {
        const savedValue = localStorage.getItem(storageKey);
        if (savedValue) {
            try {
                return JSON.parse(atob(savedValue)) as AuthModel;
            } catch (e) {
                AuthStorageService.save(defaultModel);
            }
        }
    
        return defaultModel;
    }

    static remove() {
        localStorage.removeItem(storageKey);
    }

    static getDefaultModel() {
        return defaultModel;
    }
}
