import { Modal } from 'antd';
import { ApiErrorInfo } from '../services/HttpService';

export const showErrorModal = (error: ApiErrorInfo, title: string | null = '에러가 발생했습니다.') => {
    if (error) {
        Modal.error({
            title: title,
            content: (
                <div>
                    {Object.keys(error).map((key) => {
                        return error[key];
                    }).flatMap((message, index) => {
                        return (<p key={index}>{message}</p>);
                    })}
                </div>
            )
        });
    } else {
        Modal.error({ title: title });
    }
};