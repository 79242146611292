import React from 'react';
import { ReactElement } from 'react';
import { useRecoilValue } from 'recoil';
import AuthState from '../states/AuthState';

export interface AuthProps {
    unauthenticate: ReactElement<any, any>;
    authenticate: ReactElement<any, any>;
};

const AuthProvider: React.FC<AuthProps> = (props: AuthProps): ReactElement<any> => {
    const authModel = useRecoilValue(AuthState);

    return (
        authModel.isAuthenticated ? props.authenticate : props.unauthenticate
    );
}

export default AuthProvider;