import { httpPost } from "./HttpService";

export interface IAuthPostBindingModel {
    email: string;
    password: string;
}

interface TokenDTO {
    accessToken: string;
}

export const getToken = async (model: IAuthPostBindingModel) => {
    const response = await httpPost<TokenDTO>("/auth", model);

    return response;
}
